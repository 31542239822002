const SortOrders = {
  ASC: 'asc',
  DESC: 'desc',
};

const SortByFields = {
  NAME: 'name',
  POPULARITY: 'popularity',
  DEFAULT: 'default',
};

export { SortOrders, SortByFields };
